function State(initialState) {

  for (let key in initialState) {
    if (initialState.hasOwnProperty(key)) {
      this[key] = initialState[key];
    }
  }

}

export default State;

