import {createBrowserHistory} from 'history';
import {OWN_PREFIX} from "../api";

const history = createBrowserHistory({
  basename: '',
});

const push = history.push;

history.push = function (path, state) {
  if (typeof path == 'object') {
    path.pathname = OWN_PREFIX + path.pathname;
  } else {
    path = OWN_PREFIX + path;
  }
  push.apply(history, [path, state])
};

export default history;
