import React, {useCallback, useEffect, useState} from "react";
import { Menu, Dropdown, Spin } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import api from "../../api";

const translate = (intl, id) => {
  return intl.formatMessage({ id });
};

const UserMenu = props => {

  const [userName, setUserName] = useState(null)
  const isMounted = React.useRef(false);

  const getUser = useCallback(async () => {
      const response = await api.fetch(`/V2/user/${props.userId}`);
      if(response.ok){
        const responseJson = await response.json()
        if (responseJson) {
          let user = responseJson
          const firstName = user && user.first_name ? user.first_name : "";
          const lastName = user && user.last_name ? user.last_name : "";
          let name = firstName + " " + lastName;
          if (!user.first_name && !user.last_name) name = "N/A";
          if(isMounted.current)
            setUserName(name)
        }
      }

  }, [props.userId])

  useEffect(() => {
    isMounted.current = true;
    if (props.userId && isMounted.current){
      getUser()
      return () => {
        isMounted.current = false;
      }
    }

  }, [props.userId]);


  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={`/admin/user/${props.userId ? props.userId : null}/profile`}>
          <UserOutlined />
          <span>{translate(props.intl, "profile")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={props.logout}>
        <LogoutOutlined />
        <span>{translate(props.intl, "logout")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={<div style={{ backgroundColor: "#fff" }}>{menu}</div>}
      trigger={["click"]}
    >
      <span
        style={{ verticalAlign: "super", lineHeight: 0, cursor: "pointer" }}
      >
        <UserOutlined /> {userName ? userName : <Spin size="small" />}
      </span>
    </Dropdown>
  );
};

export default injectIntl(UserMenu);
