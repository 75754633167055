/* eslint-disable no-unused-expressions */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  DatabaseOutlined,
  ExperimentOutlined,
  FileAddOutlined,
  FileOutlined,
  HistoryOutlined,
  LinkOutlined,
  TeamOutlined,
  MobileOutlined,
  FileImageOutlined,
  SendOutlined,
  NotificationOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';

import { Layout, Menu, Breadcrumb } from 'antd';
import { injectIntl } from 'react-intl';
import LanguageSelector from "../LanguageSelector";
import languageKeys from './translations';
import classes from './style.css';
import logoText from 'assets/images/icon.png'
import logo from 'assets/images/icon.png'
import Background from 'assets/images/menu.png'
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import jwt from 'jwt-decode'
import { makeSelectLocale } from "../../translations/language-provider/selectors";
import UserMenu from 'components/DropdownUserMenu';
import { bindActionCreators } from "redux";
import { updatePassword } from 'lib/providers/auth-provider/actions';
import { makeSelectToken, makeSelectRole, makeSelectIsAuth } from 'lib/providers/auth-provider/selectors';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

class AdminMenu extends React.Component {
  render() {
    const { selectedKeys, openKeys, links } = this.props;
    return (
      <Menu key={'Menu'} style={{ background: 'transparent', height:'100%', overflowX: 'hidden', overflowY: 'auto' }}
        theme="light" mode="inline"
        defaultSelectedKeys={selectedKeys || []}
        defaultOpenKeys={openKeys || []}
      >
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub0"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <TeamOutlined />
              <span>{this.props.users}</span>
            </span>
          }
        >
          <Menu.Item key="A1_backoffice">
            {links.usersBackoffice}
          </Menu.Item>
          <Menu.Item key="A1_app">
            {links.usersApp}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub1"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <DatabaseOutlined />
              <span>{this.props.dataBase}</span>
            </span>
          }
        >
          <Menu.Item key="A2">
            {links.questionnaires}
          </Menu.Item>
          <Menu.Item key="A3">
            {links.questions}
          </Menu.Item>
          <Menu.Item key="A4">
            {links.answersOptions}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub2"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <ExperimentOutlined />
              <span>{this.props.algorithm}</span>
            </span>
          }
        >
          <Menu.Item key="A6">
            {links.criteria}
          </Menu.Item>
          <Menu.Item key="A7">
            {links.criteriaUsage}
          </Menu.Item>
          <Menu.Item key="A8">
            {links.metaSkill}
          </Menu.Item>
          <Menu.Item key="A9">
            {links.principle}
          </Menu.Item>
          <Menu.Item key="A30">
            {links.algorithmConfig}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub3"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <ExperimentOutlined />
              <span>{this.props.secondaryAlgorithm}</span>
            </span>
          }
        >
          <Menu.Item key="A10">
            {links.element}
          </Menu.Item>
          <SubMenu
            style={{ paddingLeft: 0, fontSize: 11 }}
            key="sub4"
            title={
              <span style={{ paddingLeft: 0, fontSize: 13 }}>
                <FileOutlined />
                <span>{this.props.attributes}</span>
              </span>
            }
          >
            <Menu.Item key="A11">
              {links.feeling}
            </Menu.Item>
            <Menu.Item key="A12">
              {links.organ}
            </Menu.Item>
            <Menu.Item key="A13">
              {links.season}
            </Menu.Item>
            <Menu.Item key="A14">
              {links.naturalElement}
            </Menu.Item>
            <Menu.Item key="A21">
              {links.character}
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <Menu.Item key="A5">
          {links.input}
        </Menu.Item>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub5"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <SendOutlined />
              <span>{this.props.output}</span>
            </span>
          }
        >
          <Menu.Item key="A15">
            {links.algorithmOutput}
          </Menu.Item>
          <Menu.Item key="A22">
            {links.generalOutput}
          </Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub4"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <FileOutlined />
              <span>{this.props.answerQuestionnaire}</span>
            </span>
          }
        >
          <Menu.Item key="A16">
            {links.answerQuestionnaire}
          </Menu.Item>
          <Menu.Item key="A17">
            {links.history}
          </Menu.Item>
          <Menu.Item key="A18">
            {links.invites}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="A20">
          {links.pushNotifications}
        </Menu.Item>
        {/*
        <SubMenu
          style={{paddingLeft: 0, fontSize: 11}}
          key="sub6"
          title={
            <span style={{paddingLeft: 0, fontSize: 13}}>
                <MobileOutlined/>
                <span>App</span>
              </span>
          }
        >
          */}
        <Menu.Item key="A19">
          {links.app}
        </Menu.Item>
        {/*
          <Menu.Item key="A22">
            {links.answerComponent}
          </Menu.Item>
          <Menu.Item key="A23">
            {links.criteriaManagement}
          </Menu.Item>*/}
        {/*</SubMenu>*/}
      </Menu>
    );
  }
}

class ConsultantMenu extends React.Component {
  render() {
    const { selectedKeys, openKeys, links } = this.props;
    return (
      <Menu key={'Menu'} style={{ background: 'transparent' }} theme="light" mode="inline"
        defaultSelectedKeys={selectedKeys || []}
        defaultOpenKeys={openKeys || []}
      >
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="sub4"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <FileOutlined />
              <span>{this.props.answerQuestionnaire}</span>
            </span>
          }
        >
          <Menu.Item key="A16">
            {links.answerQuestionnaire}
          </Menu.Item>
          <Menu.Item key="A17">
            {links.history}
          </Menu.Item>
          <Menu.Item key="A18">
            {links.invites}
          </Menu.Item>
        </SubMenu>

      </Menu>
    );
  }
}

class SiderDemo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userId: null,
      user: null,
    };
  }


  componentDidMount() {
    if (this.props.token)
      this.setState({ userId: jwt(this.props.token).sub })
  }



  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };

  links = () => ({

    character: <Link to="/admin/characters">
      <span>{this.translate(languageKeys.characterMenu)}</span>
    </Link>,

    season: <Link to="/admin/seasons">
      <span>{this.translate(languageKeys.seasonMenu)}</span>
    </Link>,

    naturalElement: <Link to="/admin/naturalElements">
      <span>{this.translate(languageKeys.naturalElementMenu)}</span>
    </Link>,

    criteria: <Link to="/admin/criteria">
      <span>{this.translate(languageKeys.criteriaMenu)}</span>
    </Link>,

    criteriaUsage: <Link to="/admin/criteriaUsage">
      <span>{this.translate(languageKeys.criteriaUsageMenu)}</span>
    </Link>,

    metaSkill: <Link to="/admin/metaSkill">
      <span>{this.translate(languageKeys.metaSkillMenu)}</span>
    </Link>,

    principle: <Link to="/admin/principle">
      <span>{this.translate(languageKeys.principleMenu)}</span>
    </Link>,

    element: <Link to="/admin/elements">
      <span>{this.translate(languageKeys.elementMenu)}</span>
    </Link>,

    feeling: <Link to="/admin/feelings">
      <span>{this.translate(languageKeys.feelingsMenu)}</span>
    </Link>,

    organ: <Link to="/admin/organs">
      <span>{this.translate(languageKeys.organsMenu)}</span>
    </Link>,

    algorithmOutput: <Link to="/admin/algorithmOutput">
      <span>{this.translate(languageKeys.algorithmOutputMenu)}</span>
    </Link>,

    generalOutput: <Link to="/admin/generalOutput">
      <span>{this.translate(languageKeys.generalOutputMenu)}</span>
    </Link>,

    input: <Link to="/admin/input">
      <FileImageOutlined />
      <span>{this.translate(languageKeys.inputMenu)}</span>
    </Link>,

    app: <Link to="/admin/mobileApps">
      <MobileOutlined />
      <span>{this.translate(languageKeys.mobileApkMenu)}</span>
    </Link>,

    answerComponent: <Link to="/admin/mobileAnswerComponent">
      <span>{this.translate(languageKeys.answerComponentMenu)}</span>
    </Link>,

    pushNotifications:
      <Link to="/admin/pushNotifications">
        <NotificationOutlined />
        <span>Push Notifications</span>
      </Link>,


    questionnaires: <Link to="/admin/questionnaires">
      <span>{this.translate(languageKeys.questionnairesMenu)}</span>
    </Link>,

    usersBackoffice: <Link to="/admin/users/backoffice">
      <span>{this.translate(languageKeys.userBackofficeMenu)}</span>
    </Link>,

    usersApp: <Link to="/admin/users/app">
      <span>{this.translate(languageKeys.userAppMenu)}</span>
    </Link>,

    questions: <Link to="/admin/questions">
      <span>{this.translate(languageKeys.questionsMenu)}</span>
    </Link>,

    answersOptions: <Link to="/admin/answersOptions">
      <span>{this.translate(languageKeys.answer_options)}</span>
    </Link>,

    invites: <Link to="/admin/invites">
      <LinkOutlined /> &nbsp;
      <span>{this.translate(languageKeys.invitesMenu)}</span>
    </Link>,

    answerQuestionnaire: <Link
      to={{ pathname: `/user/${this.state.userId}/answerQuestionnaire`, state: { userId: this.state.userId } }}>
      <FileAddOutlined /> &nbsp;
      <span>{this.translate(languageKeys.questionnaire)}</span>
    </Link>,

    history: <Link to={{
      pathname: `/user/${this.state.userId}/answerQuestionnaire/history`,
      state: { userId: this.state.userId }
    }}>
      <HistoryOutlined /> &nbsp;
      <span>{this.translate(languageKeys.history)}</span>
    </Link>,

    criteriaManagement: <Link to={{
      pathname: `/admin/criteriaManagement`,
      state: { userId: this.state.userId }
    }}>
      <span>{this.translate(languageKeys.criteriaManagementMenu)}</span>
    </Link>,

    algorithmConfig: <Link to={{
      pathname: `/admin/algorithmConfig`,
      state: { userId: this.state.userId }
    }}>
      <span>{this.translate('algorithm_config')}</span>
    </Link>

  })


  render() {
    const { breadcrumb, level, questionnaireName, role, userSelected, element } = this.props;
    //console.log(userAuth);
    return (
      <Layout key={'admin-page'} style={{ height: '100vh' }}>
        <Layout id="components-layout-demo-custom-trigger">
          <Sider style={{ backgroundImage: `url(${Background})`, backgroundSize: 'contain', height: '100vh', overflowY: 'hidden' }} width={230}
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}>
            <div className={'logo-container'}>
              <img className={classes.logo} alt="sensor logo" src={this.state.collapsed ? logo : logoText} />
            </div>
            {role === 'admin' ? <AdminMenu
              key={
                'Menu'
              }
              output={this.translate(languageKeys.outputMenu)}
              generalOutput={this.translate(languageKeys.generalOutputMenu)}
              algorithmOuput={this.translate(languageKeys.algorithmOutputMenu)}
              criteriaManagement={this.translate(languageKeys.criteriaManagementMenu)}
              answerComponent={this.translate(languageKeys.answerComponentMenu)}
              mobileApk={this.translate(languageKeys.mobileApkMenu)}
              character={this.translate(languageKeys.characterMenu)}
              season={this.translate(languageKeys.seasonMenu)}
              naturalElement={this.translate(languageKeys.naturalElementMenu)}
              feelings={this.translate(languageKeys.feelingsMenu)}
              algorithm={this.translate(languageKeys.algorithmMenu)}
              secondaryAlgorithm={this.translate(languageKeys.secondaryAlgorithmMenu)}
              criteria={this.translate(languageKeys.criteriaMenu)}
              criteriaUsage={this.translate(languageKeys.criteriaUsageMenu)}
              metaSkill={this.translate(languageKeys.metaSkillMenu)}
              principle={this.translate(languageKeys.principleMenu)}
              element={this.translate(languageKeys.elementMenu)}
              dashboard={this.translate(languageKeys.dashboard)}
              questionnaires={this.translate(languageKeys.questionnairesMenu)}
              questions={this.translate(languageKeys.questionsMenu)}
              users={this.translate(languageKeys.usersMenu)}
              dataBase={this.translate(languageKeys.data_base)}
              answerOptions={this.translate(languageKeys.answer_options)}
              answerQuestionnaire={this.translate(languageKeys.analysis)}
              attributes={this.translate(languageKeys.attributesMenu)}
              input={this.translate(languageKeys.inputMenu)}
              new={this.translate(languageKeys.questionnaire)}
              history={this.translate(languageKeys.history)}
              userId={this.state.userId}
              {...this.props.keys}
              links={this.links()}
            /> : <ConsultantMenu
              key={
                'Menu'
              }
              criteriaManagement={this.translate(languageKeys.criteriaManagementMenu)}
              answerComponent={this.translate(languageKeys.answerComponentMenu)}
              mobileApk={this.translate(languageKeys.mobileApkMenu)}
              character={this.translate(languageKeys.characterMenu)}
              season={this.translate(languageKeys.seasonMenu)}
              naturalElement={this.translate(languageKeys.naturalElementMenu)}
              algorithm={this.translate(languageKeys.algorithmMenu)}
              secondaryAlgorithm={this.translate(languageKeys.secondaryAlgorithmMenu)}
              criteria={this.translate(languageKeys.criteriaMenu)}
              criteriaUsage={this.translate(languageKeys.criteriaUsageMenu)}
              metaSkill={this.translate(languageKeys.metaSkillMenu)}
              principle={this.translate(languageKeys.principleMenu)}
              dashboard={this.translate(languageKeys.dashboard)}
              questionnaires={this.translate(languageKeys.questionnairesMenu)}
              questions={this.translate(languageKeys.questionsMenu)}
              users={this.translate(languageKeys.usersMenu)}
              dataBase={this.translate(languageKeys.data_base)}
              answerOptions={this.translate(languageKeys.answer_options)}
              answerQuestionnaire={this.translate(languageKeys.analysis)}
              new={this.translate(languageKeys.questionnaire)}
              history={this.translate(languageKeys.history)}
              userId={this.state.userId}
              invites={this.translate(languageKeys.invitesMenu)}
              {...this.props.keys}
              links={this.links()}
            />}
          </Sider>
          <Layout>
            <Header style={{
              background: '#fff',
              padding: 0,
              position: 'relative',
              height: '9%',
              boxShadow: '0 5px 5px -5px rgba(0,0,0,0.4)'
            }}>
              {
                this.state.collapsed ?
                  <MenuUnfoldOutlined
                    className="trigger"
                    style={{ verticalAlign: 'unset', lineHeight: 0 }}
                    onClick={this.toggle}
                  /> :
                  <MenuFoldOutlined
                    className="trigger"
                    style={{ verticalAlign: 'unset', lineHeight: 0 }}
                    onClick={this.toggle}
                  />
              }

              <span style={{ position: 'absolute', left: 70, top: 17 }}>
                <Breadcrumb>
                  <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                  {level ? <Breadcrumb.Item>{this.translate(`${level}`)}</Breadcrumb.Item> : null}
                  {breadcrumb ? breadcrumb.map(path => {
                    return <Breadcrumb.Item key={path}>{this.links()[path]}</Breadcrumb.Item>;
                  }) : null}
                  {questionnaireName ?
                    <Breadcrumb.Item>{questionnaireName.filter(t => t.language.code === this.props.locale)[0].translation}</Breadcrumb.Item> : null}
                  {userSelected ? <Breadcrumb.Item>{userSelected}</Breadcrumb.Item> : null}
                  {element ? <Breadcrumb.Item>{element}</Breadcrumb.Item> : null}
                </Breadcrumb>
              </span>
              <span style={{ position: 'absolute', right: 25 }}>
                <LanguageSelector />
                <span style={{ paddingLeft: '20px' }}>
                  <UserMenu
                    userId={this.state.userId}
                    token={this.props.token}
                    logout={this.props.logout} />
                </span>
              </span>
            </Header>
            <Content style={{ padding: '1%' }}>
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  token: makeSelectToken(),
  role: makeSelectRole(),
  auth: makeSelectIsAuth()
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updatePassword
  }, dispatch)
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(injectIntl(SiderDemo));
