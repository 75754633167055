/*
 *
 * language-provider reducer
 *
 */


import { CHANGE_LOCALE } from './constants';

let locale = window.navigator.userLanguage || window.navigator.language || 'pt';
locale = locale.replace(/[-_][a-z]+$/i, '');

export const initialState = {
  locale,
};

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    default:
      return state;
  }
}

export default languageProviderReducer;
