//import {loadUser} from '../../../entities/user';
import history from '../../../constants/global-history';
import {
  SET_AUTH,
  SET_ERROR,
  SET_LOADING,
  SET_SUPPLIER,
  SET_TOKEN,
  SET_USERNAME,
  SET_PROFILE,
  SET_ROLE
} from './constants';

const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: {loading},
  };
};

export const setProfile = (profile) => {
  return {
    type: SET_PROFILE,
    payload: {profile},
  };
};

const setToken = (token) => {
  return async (dispatch, getState, server) => {
    if (token !== null) {
      await server.networkTransport.authenticate(token);
    } else {
      await server.networkTransport.deauthenticate();
    }

    dispatch({
      type: SET_TOKEN,
      payload: {token},
    });
  };
};

const setAuth = (auth) => {
  return {
    type: SET_AUTH,
    payload: {auth},
  };
};

const setRole = (role) => {
  return {
    type: SET_ROLE,
    payload: {role},
  };
};

const setUsername = (username) => {
  return {
    type: SET_USERNAME,
    payload: {username},
  };
};

const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: {error},
  };
};

export const changeSupplier = (supplierId) => {
  return {
    type: SET_SUPPLIER,
    payload: {
      supplierId
    },
  };
};

export const login = (email, password) => {
  return async (dispatch, getState, server) => {

    await dispatch(setError(null));
    await dispatch(setLoading(true));

    const settings = {
      method: 'POST',
      body: {
        email,
        password,
        //  role,
      }
    };

    let response;
    try {
      response = await server.fetch('/user/login', settings);
    } catch (e) {
      await dispatch(setLoading(false));
      throw Error(e);
    }

    const responseJson = await response.json(false);

    if (response.ok) {
      try {
        // Set the user's username
        await dispatch(setError());
        await dispatch(setUsername(email));
        await dispatch(setToken(responseJson.token));
        await dispatch(setAuth(true));
        await dispatch(setRole('admin'));

        const settingsProfile = {
          method: 'GET',
          headers: {Authorization: `Bearer ${responseJson.token}`},
        };
        response = await server.fetch('/user/profile', settingsProfile);
        const profile = await response.json();

        if (profile.role) {
          await dispatch(setRole(profile.role.role.toLowerCase()));
        } else {
          if (profile.is_admin) {
            await dispatch(setRole('admin'));
          } else {
            await dispatch(setRole('client'));
          }
        }

        // Reset the login form
      } catch (e) {
        throw Error(e)
      } finally {
        await dispatch(setLoading(false));
      }
    } else {
      await dispatch(setLoading(false));
      throw Error(responseJson.message.toLowerCase());
    }

    /* if (response.ok) {
       const role = getState().global.auth.role;
       if (role === 'admin')
         forwardTo('/admin/dashboard');
       else
         forwardTo('/client/dashboard');
     }
     */

  };


};

export const logout = (remote) => {

  return async (dispatch, getState, server) => {
    await dispatch(setLoading(true));

    const settings = {
      method: 'POST',
      headers: {Authorization: `Bearer ${getState().global.auth.token}`},
    };

    await server.fetch('/user/logout', settings);

    await dispatch(setAuth(false));
    await dispatch(setToken(null));
    await dispatch(setRole('none'));
    if(!remote){
      forwardTo('/login'); // Go to login-view page
    }
    await dispatch(setLoading(false));
  };
};

export const updatePassword = (password, newPassword) => {

  return async (dispatch, getState, server) => {
    try {
      const settings = {
        method: 'POST',
        body:{
          oldPassword: password,
          newPassword: newPassword,
        },

      };

      const response = await server.fetch('/user/password', settings);
      const responseJson = await response.json(false);

      if (response.ok) {
        // Store the token
        await dispatch(setToken(responseJson.token));
        await dispatch(setError(null))
        //await dispatch(loadUser(server));
        return responseJson

      } else {
        await dispatch(setError({
          oldPassword: {
            id: responseJson.message.toLowerCase()
          },
        }));
      }
    } catch (err) {
      alert(err);
    }
  };
};

export const handleServerError = (server, error) => {

  return async (dispatch, getState, server) => {
    if (error.statusCode === 401) {
      // Logout the user in case the token is invalid
      await dispatch(logout());
    } else {
      //alert(`${error.error} - ${error.message}`); // eslint-disable-line no-alert
    }
  };
};

// Little helper function to abstract going to different pages
const forwardTo = (location) => {
  history.push(location);
};

//Starts the reset password process: resolves with exception on error
export const initResetPassword = (email) =>
  async (dispatch, getState, server) => {
    const settings = {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    };
    const response = await server.fetch('/user/password/reset', settings);
    const responseJson = await response.json(false);
    if (!response.ok) {
      // await dispatch(setError(responseJson.message));
      throw new Error(responseJson.message);
    }
  };

export const resetPasswordConfirm = (token, newPassword) =>
  async (dispatch, getState, server) => {
    const settings = {
      method: 'POST',
      body: JSON.stringify({
        token,
        newPassword,
      }),
    };
    const response = await server.fetch(
      '/user/password/reset/confirm', settings);
    const responseJson = await response.json(false);
    if (!response.ok) {
      throw new Error(responseJson.message);
      // await dispatch(setError(responseJson.message));
    }
  };

