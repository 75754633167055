import {createSelector} from 'reselect';

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = state => state.global.language;

const selectStringResources = state => state.entities && state.entities.string_resource && state.entities.string_resource.data ? state.entities.string_resource.data : null;
const selectLanguages = state => state.entities && state.entities.language && state.entities.language.data ? state.entities.language.data : null;
const selectTranslations = state => state.entities && state.entities.translation && state.entities.translation.data ? state.entities.translation.data : null;

/**
 * Select the language locale
 */

const makeSelectLocale = () =>
  createSelector(selectLanguage, languageState => languageState.locale);


const makeSelectMessages = () =>
  createSelector([selectStringResources, selectLanguages, selectTranslations], (strings, languages, translations) => {
      const result = {};
      if (!strings || !translations || !languages) {
        return {}
      }

      for (let key in strings) {
        let stringResource = strings[key];

        for (let translation of stringResource.translations) {

          const translationInstance = translations[translation.id];
          const languageId = translationInstance.language_id;
          const language = languages[languageId];

          if (!result[language.code]) {
            result[language.code] = {}
          }

          result[language.code][stringResource.id] = translationInstance.translation;

        }

      }

      return result;
    }
  );


export {selectLanguage, makeSelectLocale, makeSelectMessages};
