function Model(initialValues) {
  for (let key in initialValues) {
    if (initialValues.hasOwnProperty(key)) {
      this[key] = initialValues[key];
    }
  }
  this.__id__ = Model.UNIQUE_ID++;
}
Model.UNIQUE_ID = 1;
Model.prototype.merge = function (newValues) {
  for (let key in newValues) {
    if (newValues.hasOwnProperty(key)) {
      this[key] = newValues[key];
    }
  }
};
Model.checkType = function (state, instance) {
  try {
    //if it really is and instance with __type__
    if (instance.__type__) {
      const type = instance.__type__; //get type
      if (!state[type]) { //create type store space if it doesn't exists
        state[type] = {};
      }
      let model = instance.__alias__ ? state[type][instance[instance.__alias__]] : state[type][instance.id]; //get the model for the current instance
      if (!model) {
        model = new Model(instance);
        if (!instance.__alias__) {
          state[type][instance.id] = model //create if not exists
        } else {
          state[type][instance[instance.__alias__]] = model;
        }
      } else {
        model.merge(instance);  //merge if it exists
      }
      //reassign attr with __type__
      for (let key in model) {
        if (model.hasOwnProperty(key)) {
          const attr = model[key];
          if (Array.isArray(attr)) {
            const newArray = [];
            for (let subAttr of attr) {
              if (subAttr != null && typeof subAttr === 'object' && subAttr.__type__ && (subAttr.id || subAttr[instance.__alias__])) {
                newArray.push(Model.checkType(state, subAttr))
              } else {
                newArray.push(subAttr)
              }
            }
            model[key] = newArray;
          } else if (attr != null && typeof attr === 'object' && attr.__type__ && (attr.id || attr[instance.__alias__])) {
            model[key] = Model.checkType(state, attr);
          } else {
            model[key] = attr;
          }
        }
      }
      //return the object Model for the instance attr
      return model;
    }
    return instance;
  } catch (e) {
    console.log(e);
    return instance;
  }
};
export default Model;
