import { addTranslations } from '../../../translations/language-provider/translations';
// todo - remove everything that comes from outside the lib folder(manage somehow)


const messages = {
  dashboard: 'Dashboard',
  questionnairesMenu: 'Questionnaires',
  questionsMenu: "Questions",
  usersMenu: "Users",
  answersMenu: 'Answer Options',
  answerQuestionnaireMenu: 'Answer Questionnaire',
  new: 'New',

  criteriaMenu: "Criteria",
  criteriaUsageMenu: "Criteria Usage",
  metaSkillMenu: "Meta Skills",
  principleMenu: "Principles",
  algorithmMenu: "Main Algorithm",
  invitesMenu: "Invitations",
  elementMenu: "Elements",
  feelingsMenu: "Feelings",
  organsMenu: "Organs",
  outputMenu: "Outputs",
  attributesMenu: "Attributes",
  inputMenu: "Inputs",
  secondaryAlgorithmMenu: "Secondary Algorithm",
  naturalElementMenu: "Natural Elements",
  seasonMenu: "Seasons",
  characterMenu: "Characters",
  answerComponentMenu: "Components",
  mobileApkMenu: "Mobile Apps",
  criteriaManagementMenu: "Criteria Management",
  apkMenu: "APK",
  textConfigMenu: "Text Config",
  algorithmOutputMenu: "Algorithm Output",
  generalOutputMenu: "General Output",
  highlightMenu: "Highlights",
  highlightTypeMenu: "Highlight Types",
  highlightOutputMenu: "Outputs",
  dailyChallengeMenu: "Daily Challenge",
  staticStringMenu: "Static Strings",
  userBackofficeMenu: "Backoffice Users",
  userAppMenu: "App Users",

  analysis: "Analysis"
};

addTranslations('en-US', messages);

export default messages;
