import { addLocaleData } from "react-intl";
// todo - remove everything that comes from outside the lib folder(manage somehow)
import en from "react-intl/locale-data/en";

addLocaleData([...en]);

const messages = {
  // GLOBALS
  /*
    Before adding new keys here, add a key mapping in the keys.json file in this directory for
    intel and abstraction purposes.
   */
  loading: "Loading",
  search: "Search",
  reset: "Reset",
  required: "Required",
  start: "Start",
  general_definitions: "General Definition",
  help: "Help",
  privacy_policy: "Privacy Policy",
  copyright: "copyright © 2019 Sensor",
  date: "Date",
  cancel: "Cancel",
  save: "Save",
  other: "Other",
  create: "Create",
  upload: "Upload",
  edit: "Edit",
  are_you_sure: "Are you sure?",
  yes: "Yes",
  no: "No",
  submit: "Submit",
  created_at: "Created at",
  updated_at: "Updated at",
  read_at: "Read at",
  view: "View",
  delete: "Delete",
  answerOptions: "Answer Options",
  dataBase: "Data Base",
  ACTIVE: "Active",
  DISABLED: "Disabled",
  created_by: "Created by",
  actions: "Actions",
  answers: "Answers",
  completed: "Completed",
  incompleted: "Incompleted",
  questionnaire: "Questionnaire",
  questions: "Questions",
  open: "Open",
  multiple: "Multiple",
  text: "Text",
  closed: "Closed",
  single: "Single",
  usersHeader: "Users",
  history: "History",
  answerQuestionnaire: "Answer Questionnaire",
  status: "Status",
  items: "Items",
  user: "User",
  up: "Up",
  down: "Down",
  ok: "OK",
  criteria: "Criteria",
  criteriaUsage: "Criteria Usage",
  metaSkill: "Meta Skills",
  principle: "Principles",
  code: "Code",
  name: "Name",
  question: "Question",
  answer: "Answer",
  all: "All",
  meta_competences: "Meta Competences",
  confirm: "Confirm",
  return: "Return",
  invites: "Invitations",
  send: "Send",
  elements: "Elements",
  attributes: "Attributes",
  energy_flow: "Energy Flow",
  sending: "Sending",
  feelings: "Feelings",
  organs: "Organs",
  outputs: "Outputs",
  output_metadata: "Output Metadata",
  input_metadata: "Input Metadata",
  metadata_bank: "Metadata Bank",
  meta_data: "Meta Data",
  inputs: "Inputs",
  image_metadata: "Image Metadata",
  image: "Image",
  output_information: "Output Information",
  none: "None",
  add_input: "Create New Input",
  add_output: "Create New Output",
  input_name: "Input Name",
  input_content: "Input Content",
  type: "Type",
  output_content: "Output Content",
  output_name: "Output Name",
  change_type: "Change Type",
  input_information: "Input Information",
  dowload_excel_users: "Export Excel",
  excel_users_title: "Users_List_",
  printPDF: "Print PDF",
  element_attributes: "Element’s Attributes",
  attributes_bank: "Attributes Bank",
  sense: "Senses",
  duplicate: "Duplicate",
  natural_elements: "Natural Elements",
  add_natural_element: "Create New Natural Element",
  create_new_natural_element: "Create New Natural Element",
  edit_natural_element: "Edit Natural Element",
  add_season: "Create New Season",
  create_new_season: "Create New Season",
  edit_season: "Edit Season",
  season: "Season",
  add_character: "Create New Character",
  create_new_character: "Create New Character",
  edit_character: "Edit Character",
  character: "Characters",
  basic_settings: "Basic Settings",
  security_settings: "Security Settings",
  first_name: "First Name",
  last_name: "Last Name",
  birth_place: "Birth Place",
  job_name: "Job Name",
  nationality: "Nationality",
  gender: "Gender",
  birth_date: "Birth Date",
  old_password: "Old Password",
  new_password: "New Password",
  password: "Password",
  confirm_password: "Confirm Password",
  change: "Change",
  profile: "User Profile",
  male: "Male",
  female: "Female",
  change_password: "Change Password",
  invalid_password: "Invalid Password",
  password_changed: "Your password was changed",
  required_password: "Please input your password!",
  match_password: "The two passwords that you entered do not match!",
  input_email: "Please input your Email!",
  invalid_email: "Invalid Email",
  profile_updated: "Profile Updated",
  profile_updated_message: "Your profile was updated",
  creation_date: "Creation Date",
  no_information: "No Information",
  manage_question_criteria: "Manage Question’s Criterias",
  logout: "Logout",
  title: "Title",
  users: "Users",
  message: "Message",
  new_notification: "New Notification",
  send_notification_to: "Send Notification To",
  all_users: "All Users",
  some_users: "Some Users",
  dynamic_keys: "Dynamic Keys",
  confirm_message_disable: "Would you like to disable",
  confirm_message_enable: "Would you like to enable",
  confirm_this_male: "this",
  confirm_this_female: "this",
  enable: "enable",
  disable: "disable",
  answer_option: "answer option",
  one_criteria: "criteria",
  one_criteria_usage: "criteria usage",
  one_meta_skill: "meta skill",
  one_principle: "principle",
  resending_invitation: "Resending Invitation",
  resending_invitation_to: "Resending invitation to",
  to_fill_questionnaire: "to fill the questionnaire",
  clipboard: "Copy Invitation Code",
  save_changes: "Save Changes",
  discard_changes: "Discard changes",
  mobile_answer_component: "Components",
  one_mobile_answer_component: "Mobile Answer Component",
  add_mobile_answer_component: "Create New Component",
  create_new_mobile_answer_component: "Create New Component",
  edit_mobile_answer_component: "Edit Component",
  disable_mobile_answer_component: "Disable Component",
  enable_mobile_answer_component: "Enable Component",
  general: "General",
  algorithm_values: "Algorithm Values",
  skill_anchor: "Leveraging skills",
  skill_compensated: "Compensation Skill",
  skill_decompensated: "Decompensation Skill",
  criteria_management: "Criteria Management",
  free_in_app: "Free in APP",
  add_criteria: "Add New Criteria",
  edit_criteria: "Edit Criteria",
  free: "Free",
  initial_date: "Initial Date",
  expire_date: "Expire Date",
  description: "Description",
  entities_assigned: "Entities Assigned",
  entities: "Entities",
  effect_type: "Effect Type",
  effect: "Effects",
  create_new_highlight_output: "Create New Highlight Output",
  create_new_highlight_question: "Create New Highlight Question",
  highlight_type: "Highlight Types",
  create_new_highlight_type: "Create New Highlight Type",
  link_android: "Link Android",
  link_ios: "Link IOS",
  mobile_apps: "Mobile Apps",
  text_config: "Text Config",
  assign_entities: "Assign Entities",
  disable_text_config: "Disable Text Config",
  enable_text_config: "Enable Text Config",
  env: "Environment Variables",
  add_env: "Create New Environment Variable",
  daily_challenge: "Daily Challenge",
  number_of_inputs: "Number of Inputs",
  min_days_of_interval: "Min Days of Interval",
  add_highlight_type: "Add Highlight Type",
  daily_challenge_saved: "Daily Challenge Saved",
  create_new_highlight_questionnaire: "Create New Highlight Questionnaire",
  edit_highlight_questionnaire: "Edit Highlight Questionnaire",
  edit_highlight_question: "Edit Highlight Question",
  type_image: "IMAGE",
  type_text: "TEXT",
  edit_question: "Edit Question",
  clean_filters: "Reset Filters",
  static_string: "Static Strings",
  add_static_string: "Create New Static String",
  string_value: "String Value",
  add_feeling: "Create New Feeling",
  edit_feeling: "Edit Feeling",
  add_organ: "Create New Organ",
  edit_organ: "Edit Organ",
  input_translation: "Please, input all translations",
  select_answer_type: "Please, select the answer type",
  set_criteria_as_free: "Set Criteria as Free",
  remove_criteria_as_free: "Remove Criteria as Free",
  confirm_set_free: "Would you like to set this Criteria as free?",
  confirm_remove_free: "Would you like to remove this Criteria as free?",
  free_criterias: "Free Criteria",
  confirm_set_question_non_required:
    "Would you like to set this question as non-required ?",
  confirm_set_question_required:
    "Would you like to set this question as required ?",
  set_non_required: "Set question as non-required",
  set_required: "Set question as required",
  duplicate_questionnaire: "The Questionnaire has been duplicated",
  add: "Add",
  update_questionnaire: "The Questionnaire has been updated",
  add_new_app: "Add New App",
  translate: "Translate",
  delete_general_output:
    "This output cannot be deleted because it is being used in the APP",
  alternative_report: "Alterative Report",
  edit_static_string: "Edit Static String",
  edit_rich_text: "Edit Rich Text",
  add_rich_text: "Create New Rich Text",
  plain_text: "Plain Text",
  rich_text: "Rich Text",
  create_new_answer_option: "Create New Answer Option",
  edit_answer_option: "Edit Answer Option",
  relevant: "Relevant",
  irrelevant: "Irrelevant",
  enable_criteria: "Enable Criteria",
  disable_criteria: "Disable Criteria",
  set_criteria_as_relevant: "Set Criteria as Relevant",
  remove_criteria_as_relevant: "Remove Criteria as Relevant",
  confirm_set_relevant: "Would you like to set this Criteria as Relevant?",
  confirm_remove_relevant:
    "Would you like to remove this Criteria as Relevant?",
  relevant_criteria: "Relevant Criteria",
  surname: "Surname",
  role: "Role",
  create_user: "Create User",
  week: "Week",
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  add_week: "Add Week",
  general_insights: "General Insights",
  resources_of_focus_insights: "Resources of Focus Insights",
  act_quiz_call_to_action: "Act Quiz Call to Action",
  general_criteria: "General Criteria",
  self_criteria: "Self Criteria",
  social_criteria: "Social Criteria",
  professional_criteria: "Professional Criteria",
  report_and_react: "Report and React",
  heart_re_question: "Heart Re Question",
  highlight_schedule: "Highlight Schedule",
  delete_week: "Delete week",
  next_question: "Next Question",
  previous_question: "Previous Question",
  answer_saved: "Answer has been saved",
  import: "Import",
  export: "Export",
  import_data: "Import Data",
  file_imported: "File Exported",
  data_exported: "Data Exported",
  error_exporting_data: "Error Exporting Data",
  export_data: "Export Data",
  invitation: "Invitation",
  disable_invitation: "Disable Invitation",
  enable_invitation: "Enable Invitation",
  algorithm_config: "Algorithm Config",
  user_evolution: "User Evolution",
  no_data: "No Data",
  EMAIL_ALREADY_EXISTS: "E-mail Already Exists",
  answer_type: "Answer Type",
  ADMIN: "Admin",
  CONSULTANT: "Consultant",
  start_date: "Start date",
  end_date: "End Date",
  field_required: "Field Required",
  report: "Report",
  select_date: "Select date",
  summary_of_resources: "Summary of resources",
  type_to_search: "Type to search",
  confirm_invitation: "Are you sure you want to send this invitation?",
  sending_email_to: "Sending email to",
  to_fill_the_questionnaire: "to fill the questionnaire",
  sending_invitation: "Sending Invitation",
  invitation_sent: "Invitation sent",
  an_email_was_sent_to: "An email was sent to"

};

export default messages;
