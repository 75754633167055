const ACTION_TYPE_ADD = '$$ENTITIES/ADD';
const ACTION_TYPE_REMOVE = '$$ENTITIES/REMOVE';
const ACTION_TYPE_CLEAR = '$$ENTITIES/CLEAR';
const ACTION_TYPE_SET_LOADING = '$$ENTITIES/SET_LOADING';
const ACTION_TYPE_SET_ERROR = '$$ENTITIES/SET_ERROR';

export const actionTypes = {
  ACTION_TYPE_ADD,
  ACTION_TYPE_REMOVE,
  ACTION_TYPE_CLEAR,
  ACTION_TYPE_SET_LOADING,
  ACTION_TYPE_SET_ERROR,
};


export const add = (data) => ({
  type: ACTION_TYPE_ADD,
  payload: Array.isArray(data) ? data : [data],
});

export const remove = (type, id) => ({
  type: ACTION_TYPE_REMOVE,
  payload: {
    type,
    id,
  },
});

export const clear = (type) => ({
  type: ACTION_TYPE_CLEAR,
  payload: {
    type,
  },
});

export const setLoading = (type, isLoading, loadingId) => ({
  type: ACTION_TYPE_SET_LOADING,
  payload: {
    type,
    isLoading,
    loadingId
  },
});

export const setError = (type, error) => ({
  type: ACTION_TYPE_SET_ERROR,
  payload: {
    type,
    error,
  },
});

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const load = (type, loader, options) => {
  if (typeof type !== 'string' || type === '') {
    throw new Error('Entity type is required.');
  }

  const settings = {
    silent: false,
    ...options,
  };

  //console.log(options)
  //console.log(settings)

  return async (dispatch) => {
    const loadingId = uuidv4();
    if (!settings.silent) {
      await dispatch(setLoading(type, true, loadingId));
    }

    try {
      const data = await loader();
      await dispatch(setError(type, null));

      await dispatch(add(data));
      if (options && options.onSuccess) {
        options.onSuccess(data);
      }

    } catch (error) {
      console.log(error)
      await dispatch(setError(type, error));
    } finally {
      await dispatch(setLoading(type, false, loadingId));
    }
  };
};

