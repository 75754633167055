import {handleServerError} from '../../lib/providers/auth-provider/actions';
import {load} from '../entity-provider';
import {questionDeserialize} from 'utils/JSONAPI';


export function getLanguages() {
  return async (dispatch, getState, server) => {
    //const state = getState();

    await dispatch(load('language', async () => {
        const response = await server.fetch(`/languages/`);

        const responseJson = await response.json();

        if (response.ok) {
          return await questionDeserialize(responseJson);
        } else {
          await dispatch(handleServerError(server, responseJson));
          throw new Error(responseJson);
        }
      }
    ));
  };
}

export function textTranslation(text, source, target) {

  return async (dispatch, getState, server) => {
    //const state = getState();

    const options = {
      method: 'POST',
      body: {
        text,
        source,
        target
      }
    };

    return await dispatch(async () => {

        const response = await server.fetch(`/languages/translate`, options);
        const responseJson = await response.json();
        if (response.ok) {
          return responseJson.translations[0].translation;
        } else {
          await dispatch(handleServerError(server, responseJson));
          throw new Error(responseJson);
        }
      }
    );
  };
}
