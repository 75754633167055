'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

// Imports


var _url = require('url');

var _url2 = _interopRequireDefault(_url);

var _RESTSocket = require('./RESTSocket');

var _RESTSocket2 = _interopRequireDefault(_RESTSocket);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var isBinary = function isBinary(obj) {
    return global.Buffer && global.Buffer.isBuffer(obj) || global.ArrayBuffer && obj instanceof ArrayBuffer || global.Blob && obj instanceof Blob || global.File && obj instanceof File;
};

var defaultOptions = {
    autoConnectSocket: true
};

var NetworkTransport = function () {
    function NetworkTransport(serverUrl, options) {
        var _this = this;

        _classCallCheck(this, NetworkTransport);

        this.options = Object.assign({}, defaultOptions, options);
        this.transport = 'fetch';
        this.token = null;
        this.server = _url2.default.parse(serverUrl);
        this.socket = new _RESTSocket2.default(this.server.href, { jsonp: false });

        if (this.options.autoConnectSocket) {
            this.socket.connect();
        }

        this.socket.on('connect', function () {
            _this.transport = 'socket';

            if (typeof _this.token === 'string') {
                _this.socket.authenticate(_this.token);
            }
        });

        this.socket.on('disconnect', function () {
            _this.transport = 'fetch';
        });
    }

    _createClass(NetworkTransport, [{
        key: 'fetch',
        value: function (_fetch) {
            function fetch(_x, _x2, _x3) {
                return _fetch.apply(this, arguments);
            }

            fetch.toString = function () {
                return _fetch.toString();
            };

            return fetch;
        }(function (path, options, transport) {
            try {
                var processedOptions = Object.assign({}, options);

                switch (transport || this.transport) {
                    case 'socket':
                        {
                            return this.socket.fetch(path, processedOptions);
                        }

                    case 'fetch':
                        {
                            var url = _url2.default.resolve(this.server.href, path);

                            var extraHeaders = [];

                            if (typeof this.token === 'string') {
                                extraHeaders.push({
                                    Authorization: 'Bearer ' + this.token
                                });
                            }

                            if (processedOptions.body) {
                                if (isBinary(processedOptions.body)) {
                                    extraHeaders.push({
                                        'Content-Type': 'application/octet-stream'
                                    });
                                } else if(!(processedOptions.body instanceof FormData)){
                                    processedOptions.body = JSON.stringify(processedOptions.body);
                                    extraHeaders.push({
                                        'Content-Type': 'application/json'
                                    });
                                }
                            }

                            // Compose headers
                            processedOptions.headers = Object.assign.apply(Object, [{}, processedOptions.headers].concat(extraHeaders));

                            // Fetch
                            return fetch(url, processedOptions);
                        }

                    default:
                        return Promise.reject(new Error('Unknown transport.'));
                }
            } catch (err) {
                return Promise.reject(err);
            }
        })
    }, {
        key: 'authenticate',
        value: function authenticate(token) {
            this.token = token;

            if (this.transport === 'socket') {
                return this.socket.authenticate(token);
            }

            return Promise.resolve();
        }
    }, {
        key: 'deauthenticate',
        value: function deauthenticate() {
            this.token = null;

            if (this.transport === 'socket') {
                return this.socket.deauthenticate();
            }

            return Promise.resolve();
        }
    }, {
        key: 'connectSocket',
        value: function connectSocket() {
            this.socket.connect();
        }
    }, {
        key: 'disconnectSocket',
        value: function disconnectSocket() {
            this.socket.disconnect();
        }
    }, {
        key: 'onSocket',
        value: function onSocket(event, listenerFn) {
            this.socket.on(event, listenerFn);
        }
    }, {
        key: 'offSocket',
        value: function offSocket(event, listenerFn) {
            this.socket.off(event, listenerFn);
        }
    }]);

    return NetworkTransport;
}();

exports.default = NetworkTransport;
