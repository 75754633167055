import { addLocaleData } from "react-intl";
import pt from "react-intl/locale-data/pt";

const messages = {
  // GLOBALS
  /*
    Before adding new keys here, add a key mapping in the keys.json file in this directory for
    intl and abstraction purposes.
   */
  loading: "A Carregar",
  search: "Procurar",
  reset: "Repor",
  required: "Obrigatório",
  start: "Início",
  general_definitions: "Definições Gerais",
  help: "Ajuda",
  privacy_policy: "Politica de Privacidade",
  copyright: "copyright © 2019 Sensor",
  date: "Data",
  cancel: "Cancelar",
  save: "Guardar",
  other: "Outro",
  create: "Criar",
  upload: "Carregar",
  edit: "Editar",
  are_you_sure: "Tem a certeza?",
  yes: "Sim",
  no: "Não",
  created_at: "Criado em",
  updated_at: "Atualizado em",
  read_at: "Lido em",
  title: "Título",
  users: "Utilizadores",
  message: "Mensagem",
  submit: "Submeter",
  view: "Visualizar",
  delete: "Apagar",
  ACTIVE: "Ativo",
  DISABLED: "Desativado",
  created_by: "Criado por",
  actions: "Acções",
  answers: "Respostas",
  completed: "Completo",
  incompleted: "Incompleto",
  questionnaire: "Questionário",
  questions: "Perguntas",
  open: "Aberta",
  multiple: "Multipla",
  text: "Texto",
  closed: "Fechada",
  single: "Única",
  usersHeader: "Utilizadores",
  history: "Histórico",
  answerQuestionnaire: "Responder Questionário",
  status: "Estado",
  items: "Itens",
  user: "Utilizador",
  up: "Para cima",
  down: "Para baixo",
  ok: "Sim",
  criteria: "Critérios",
  criteriaUsage: "Uso de Critérios",
  metaSkill: "Meta Habilidades",
  principle: "Princípios",
  code: "Código",
  name: "Nome",
  question: "Pergunta",
  answer: "Resposta",
  all: "Todos",
  meta_competences: "Meta Competências",
  confirm: "Confirmar",
  return: "Voltar",
  invites: "Convites",
  send: "Enviar",
  elements: "Elementos",
  attributes: "Atributos",
  energy_flow: "Fluxo de Energia",
  sending: "A Enviar",
  feelings: "Sentimentos",
  organs: "Órgãos",
  meta_data: "Meta Dados",
  metadata_bank: "Banco de Meta Dados",
  output_metadata: "Meta Dados do Output",
  input_metadata: "Meta Dados do Input",
  outputs: "Outputs",
  inputs: "Inputs",
  image_metadata: "Meta Dados da Imagem",
  image: "Imagem",
  output_information: "Informação do Output",
  none: "Nenhum",
  add_input: "Adicionar Input",
  add_output: "Adicionar Output",
  input_name: "Nome dos Inputs",
  input_content: "Conteúdo do Input",
  type: "Tipo",
  output_content: "Conteúdo do Output",
  output_name: "Nome do Output",
  change_type: "Alterar tipo",
  input_information: "Informação do Input",
  dowload_excel_users: "Exportar para Excel",
  excel_users_title: "Lista_Utilizadores",
  printPDF: "Imprimir em PDF",
  element_attributes: "Atributos do Elemento",
  attributes_bank: "Banco de Atributos",
  sense: "Sentidos",
  duplicate: "Duplicar",
  natural_elements: "Elementos Naturais",
  add_natural_element: "Criar Novo Elemento Natural",
  create_new_natural_element: "Criar Novo Elemento Natural",
  edit_natural_element: "Editar Elemento Natural",
  add_season: "Criar Nova Estação",
  create_new_season: "Criar Nova Estação",
  edit_season: "Editar Estação",
  season: "Estação",
  add_character: "Criar Novo Carácter",
  create_new_character: "Criar Novo Carácter",
  edit_character: "Editar Carácter",
  character: "Caracteres",
  basic_settings: "Definições Básicas",
  security_settings: "Definições de Segurança",
  first_name: "Primeiro Nome",
  last_name: "Último Nome",
  birth_place: "Local de Nascimento",
  job_name: "Profissão",
  nationality: "Nacionalidade",
  gender: "Género",
  birth_date: "Data de Nascimento",
  old_password: "Password",
  new_password: "Nova Password",
  password: "Password",
  confirm_password: "Confirmar Palavra-chave",
  change: "Alterar",
  profile: "Perfil de Utilizador",
  male: "Masculino",
  female: "Feminino",
  change_password: "Alterar Palavra-chave",
  invalid_password: "Palavra-chave Inválida",
  password_changed: "A sua palavra-chave foi alterada",
  required_password: "Por favor, introduza a palavra-chave",
  match_password: "Palavras-chave diferentes",
  input_email: "Por favor, introduza o seu email",
  invalid_email: "Email inválido",
  profile_updated: "Perfil atualizado",
  profile_updated_message: "O seu perfil foi atualizado",
  creation_date: "Data do Registo",
  no_information: "Sem Informação",
  manage_question_criteria: "Gerir Critérios da Pergunta",
  logout: "Sair",
  new_notification: "Nova Notificação",
  send_notification_to: "Enviar Notificação Para",
  all_users: "Todos os Utilizadores",
  some_users: "Alguns Utilizadores",
  dynamic_keys: "Chaves dinâmicas",
  confirm_message_disable: "Gostaria de desactivar",
  confirm_message_enable: "Gostaria de activar",
  confirm_this_male: "este",
  confirm_this_female: "esta",
  enable: "activar",
  disable: "desactivar",
  answer_option: "Opção de resposta",
  one_criteria: "critério",
  one_criteria_usage: "utilidade de critérios",
  one_meta_skill: "competência",
  one_principle: "princípio",
  resending_invitation: "Reenviar Convite",
  resending_invitation_to: "Reenviar convite para",
  to_fill_questionnaire: "para preencher o questionário",
  clipboard: "Copiar Código do Convite",
  save_changes: "Guardar as Alterações",
  discard_changes: "Descartar as Alterações",
  mobile_answer_component: "Componentes",
  one_mobile_answer_component: "Componente de Resposta Mobile",
  add_mobile_answer_component: "Criar Novo Componente de Resposta Mobile",
  create_new_mobile_answer_component:
    "Criar Novo Componente de Resposta Mobile",
  edit_mobile_answer_component: "Editar Componente de Resposta Mobile",
  disable_mobile_answer_component: "Desactivar Componente de Resposta Mobile",
  enable_mobile_answer_component: "Activar Componente de Resposta Mobile",
  general: "Geral",
  algorithm_values: "Valores do Algoritmo",
  skill_anchor: "Habilidades de Alavancagem",
  skill_compensated: "Habilidade de Compensação",
  skill_decompensated: "Habilidade de Descompensação",
  criteria_management: "Gestão de Critérios",
  free_in_app: "Grátis na APP",
  add_criteria: "Adicionar Novo Critério",
  edit_criteria: "Editar Critério",
  free: "Grátis",
  initial_date: "Data de Inicio",
  expire_date: "Data de Fim",
  description: "Descrição",
  entities_assigned: "Entidades Atribuídas",
  entities: "Entidades",
  effect_type: "Tipo de Efeito",
  effect: "Efeitos",
  create_new_highlight_output: "Criar Novo Highlight Output",
  highlight_type: "Tipos de Destaque",
  create_new_highlight_type: "Criar Novo Tipo de Destaque",
  link_android: "Hiperligação Android",
  link_ios: "Hiperligação IOS",
  mobile_apps: "Aplicações Móveis",
  text_config: "Configuração de Texto",
  assign_entities: "Atribuir Entidades",
  disable_text_config: "Desactivar configuração de texto",
  enable_text_config: "Activar configuração de texto",
  env: "Variáveis de Ambiente",
  add_env: "Criar Nova Variável de Ambiente",
  daily_challenge: "Desafio Diário",
  number_of_inputs: "Número de Inputs",
  min_days_of_interval: "Mínimo dias de Intervalo",
  add_highlight_type: "Adicionar Tipo de Destaque",
  daily_challenge_saved: "Desafio Diário guardado",
  create_new_highlight_questionnaire: "Criar Novo Highlight - Questionário",
  create_new_highlight_question: "Criar Novo Highlight - Pergunta",
  edit_highlight_questionnaire: "Editar Highlight - Questionário",
  edit_highlight_question: "Editar Highlight - Pergunta",
  type_image: "IMAGEM",
  type_text: "TEXTO",
  edit_question: "Editar Pergunta",
  clean_filters: "Limpar Filtros",
  static_string: "Strings Estáticas",
  add_static_string: "Criar Nova String Estática",
  string_value: "Valor da String",
  add_feeling: "Criar Novo Sentimento",
  edit_feeling: "Editar Sentimento",
  add_organ: "Criar Novo Orgão",
  edit_organ: "Editar Orgão",
  input_translation: "Por favor, insira todas as traduções.",
  select_answer_type: "Por favor, seleccione o tipo de resposta.",
  set_criteria_as_free: "Definir Critério como gratuito",
  remove_criteria_as_free: "Remover Critério",
  confirm_set_free: "Gostaria de definir este critério como gratuito?",
  confirm_remove_free: "Gostaria de remover este Critério?",
  free_criterias: "Critérios Grátis",
  confirm_set_question_non_required:
    "Gostaria de definir esta pergunta como não-obrigatória ?",
  confirm_set_question_required:
    "Gostaria de definir esta pergunta como obrigatória ?",
  set_non_required: "Definir pergunta como não obrigatória",
  set_required: "Definir pergunta como obrigatória",
  duplicate_questionnaire: "O questionário foi duplicado",
  add: "Adicionar",
  update_questionnaire: "O questionário foi actualizado",
  add_new_app: "Adicionar Nova App",
  translate: "Traduzir",
  delete_general_output:
    "Este output não pode ser eliminado porque está a ser utilizado na APP",
  alternative_report: "Relatório alternativo",
  edit_static_string: "Editar Texto Estático",
  edit_rich_text: "Editar Texto Formatado",
  add_rich_text: "Criar Novo Texto Formatado",
  plain_text: "Texto Simples",
  rich_text: "Texto Formatado",
  create_new_answer_option: "Criar Nova Opção de Resposta",
  edit_answer_option: "Editar Opção de Resposta",
  relevant: "Relevante",
  irrelevant: "Irrelevante",
  enable_criteria: "Activar Critério",
  disable_criteria: "Desactivar Critério",
  set_criteria_as_relevant: "Definir critério como relevante",
  remove_criteria_as_relevant: "Remover critério como relevante",
  confirm_set_relevant: "Gostaria de definir este critério como relevante?",
  confirm_remove_relevant: "Gostaria de remover este critério como relevante?",
  relevant_criteria: "Critérios Relevantes",
  surname: "Apelido",
  role: "Função",
  create_user: "Criar Utilizador",
  week: "Semana",
  sunday: "Domingo",
  monday: "Segunda-Feira",
  tuesday: "Terça-Feira",
  wednesday: "Quarta-Feira",
  thursday: "Quinta-Feira",
  friday: "Sexta-Feira",
  saturday: "Sábado",
  add_week: "Adicionar Semana",
  general_insights: "Percepções Gerais",
  resources_of_focus_insights: "Recursos de insights de foco",
  act_quiz_call_to_action: "Questionário de apelo à ação",
  general_criteria: "Critérios Gerais",
  self_criteria: "Critérios Próprios",
  professional_criteria: "Critérios Profissionais",
  social_criteria: "Critérios Sociais",
  report_and_react: "Reportar e reagir",
  heart_re_question: "Pergunta do Coração",
  highlight_schedule: "Agendamento",
  delete_week: "Apagar semana",
  next_question: "Pergunta Seguinte",
  previous_question: "Pergunta Anterior",
  answer_saved: "A resposta foi guardada",
  importar: "Importar",
  exportar: "Exportar",
  import_data: "Importar Dados",
  file_imported: "Ficheiro exportado",
  data_exported: "Dados exportados",
  error_exporting_data: "Erro ao exportar dados",
  export_data: "Exportar Dados",
  invitation: "Convite",
  disable_invitation: "Desactivar Convite",
  enable_invitation: "Activar Convite",
  algorithm_config: "Configuração do Algoritmo",
  user_evolution: "Evolução do Utilizador",
  no_data: "Sem Dados",
  EMAIL_ALREADY_EXISTS: "E-mail já existe",
  answer_type: "Tipo de Resposta",
  import: "Importar",
  export: "Exportar",
  ADMIN: "Administrador",
  CONSULTANT: "Consultor",
  start_date: "Data Inicial",
  end_date: "Data Final",
  field_required: "Campo Obrigatório",
  report: "Relatório",
  type_to_search: "Escreva para pesquisar",
  summary_of_resources: "Resumo de recursos",
  select_date: "Selecione uma data",
  confirm_invitation: "Tem a certeza que quer enviar este convite?",
  sending_email_to: "A enviar email para",
  to_fill_the_questionnaire: "para preencher o questionário",
  sending_invitation: "A enviar convite",
  invitation_sent: "Convite enviado",
  an_email_was_sent_to: "Um email foi enviado para"


};


addLocaleData([...pt]);

export default messages;
