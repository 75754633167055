import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Menu, Dropdown } from "antd";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";
import { makeSelectLocale } from "translations/language-provider/selectors";
import { changeLocale } from "translations/language-provider/actions";
import {
  getLanguages,
  makeSelectLanguages,
  makeSelectIsLanguagesLoading,
  textTranslation
} from "entities/languages";
import ReactCountryFlag from "react-country-flag";

class LanguageSelector extends PureComponent {
  componentDidMount() {
    this.props.getLanguages();
  }

  handleMenuClick = e => {
    if (this.props.setMessage && this.props.message) {
      this.props.setLoading(true);
      this.props
        .textTranslation(this.props.message, this.props.source, e.key)
        .then(response => {
          this.props.setMessage(response);
          this.props.setLoading(false);
        });
      this.props.setLanguage(e.key);
    } else this.props.changeLocale(e.key);
  };

  getMenu() {
    if (this.props.loading) {
      return "..loading";
    }

    if (!Array.isArray(this.props.languages)) {
      return "..loading";
    }

    return (
      <Menu onClick={this.handleMenuClick}>
        {this.props.languages.map(i => {
          return (
            <Menu.Item key={i.code}>
              <ReactCountryFlag
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em"
                }}
                title={i.code.toUpperCase()}
                countryCode={
                  i.code.toUpperCase() === "EN" ? "GB" : i.code.toUpperCase()
                }
              />
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  render() {
    return (
      <Dropdown
        overlay={
          <div style={{ backgroundColor: "#fff" }}>{this.getMenu()}</div>
        }
        trigger={["click"]}
      >
        <span
          style={{
            verticalAlign: "super",
            lineHeight: 0,
            cursor: "pointer",
            ...this.props.style
          }}
          className="ant-dropdown-link"
        >
          <ReactCountryFlag
            svg
            style={{
              width: "1.5em",
              height: "1.5em"
            }}
            title={
              this.props.source
                ? this.props.source.toUpperCase()
                : this.props.locale.toUpperCase()
            }
            countryCode={
              this.props.source
                ? this.props.source.toUpperCase() === "EN"
                  ? "GB"
                  : this.props.source.toUpperCase()
                : this.props.locale.toUpperCase() === "EN"
                ? "GB"
                : this.props.locale.toUpperCase()
            }
          />
        </span>
      </Dropdown>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  languages: makeSelectLanguages(),
  loading: makeSelectIsLanguagesLoading()
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeLocale,
      getLanguages,
      textTranslation
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
