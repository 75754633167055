// Initial routing state
import {LOCATION_CHANGE} from "react-router-redux";

const routeInitialState = {
  location: null,
};

/**
 * Merge route into the global application state
 */
export default function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return {...state, ...{location: action.payload}};
    default:
      return state;
  }
}

