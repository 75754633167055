import { addTranslations } from '../../../translations/language-provider/translations';
// todo - remove everything that comes from outside the lib folder(manage somehow)


const messages = {
  dashboard: 'Dashboard',
  questionnairesMenu: 'Questionnaires',
  questionsMenu: "Questions",
  usersMenu: "Utilisateurs",
  answersMenu: 'Réponses',
  answerQuestionnaireMenu: 'Répondre au questionnaire',
  new: 'Nouveau',

  criteriaMenu: "Critères",
  criteriaUsageMenu: "Imputations",
  metaSkillMenu: "Méta compétences",
  principleMenu: "Principes",
  algorithmMenu: "Algorithme Principal",
  invitesMenu: "Invitation",
  elementMenu: "Éléments",
  feelingsMenu: "Sentiments",
  organsMenu: "Organes",
  outputMenu: "Outputs",
  attributesMenu: 'Attributs',
  inputMenu: "Entrées",
  secondaryAlgorithmMenu: "Algorithme Secondaire",
  naturalElementMenu: "Éléments Naturels",
  seasonMenu: "Saisons",
  characterMenu: "Caractéres",
  answerComponentMenu: "Composants",
  mobileApkMenu: "Applications Mobile",
  criteriaManagementMenu: "Gestion des Critères",
  apkMenu: "APK",
  textConfigMenu: "Config Texte",
  algorithmOutputMenu: "Output d'Algorithme",
  generalOutputMenu: "Output Générale",
  highlightMenu: "Highlights",
  highlightTypeMenu: "Highlight Types",
  highlightOutputMenu: "Outputs",
  dailyChallengeMenu: "Challenge Quotidien",
  staticStringMenu: "Strings Statiques",
  userBackofficeMenu: "Utilisateurs Backoffice",
  userAppMenu: "Utilisateurs de APP",
  analysis: "Analyses"



};

addTranslations('fr-FR', messages);

export default messages;
