// Imports
import qs from 'qs';
import jsonpack from 'jsonpack'
import {PREFIX} from 'api/'


const buildEnhancedFetch = (networkTransport) =>
  async (path,
         options = {
           method: 'GET',
           filters: {},
           include: [],
           orderBy: null,
           page: {},
           limit: null,
           withGraphFetched: null,
           userType: '',
           questionnaireAnswerId: null
         },
         transport) => {
    const query = {...options.query};


    const {
      filters,
      include,
      orderBy,
      page,
      limit,
      withGraphFetched,
      userType,
      ...other
    } = options;

    const store = require('../store/index').default;

    if (!path.includes('language'))
      query.language = store.getState().global.language.locale;

    if (userType) {
      query.userType = userType;
    }

    // Process filters
    if (typeof filters === 'object') {
      query.filters = filters
    }

    // Process include
    if (include) {
      query.include = include;
    }

    // Process include
    if (page) {
      query.page = page;
    }
    // Process include
    if (withGraphFetched) {
      query.withGraphFetched = withGraphFetched;
    }

    // Process sort
    if (typeof orderBy === 'string' && orderBy) {
      query.orderBy = orderBy;
    }

    // Process limit
    if (limit) {
      query.limit = limit;
    }


    // Build URL
    let url;
    if (!path.includes("?"))
     url = `${PREFIX}${path}${qs.stringify(query, {addQueryPrefix: true})}`;
    else
      url = `${PREFIX}${path}`;

    // Fetch

    const result = await networkTransport.fetch(url, other, transport);
    result.__proto__.json = async function (unpack = true) {

      return this.text().then((text) => {
        try {
          return jsonpack.unpack(text)
        } catch (e) {
          return JSON.parse(text)
        }
      });
    };
    return result;
  };

export default buildEnhancedFetch;
